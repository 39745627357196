<template>
  <!-- 退出答题提示 -->
  <van-dialog v-model="show"
              :title="$t('module.hint')"
              :showConfirmButton="false">
    <van-icon name="cross"
              @click="close" />
    <span>{{$t('exitPrompt.exitTxt1')}}</span><br />
    <!-- <span>如果现在退出将不会有任何记录</span> -->
    <p>{{$t('exitPrompt.exitTxt2')}}<span style="color:#F53838; margin:0 10px;">{{noAnswer}}</span></p>
    <div class="footer">
      <van-button type="info"
                  @click="goOn">{{$t('exitPrompt.exitTxt3')}}</van-button>
      <van-button type="default"
                  style="background:#CCCCCC; color:#fff;"
                  @click="leave">{{$t('exitPrompt.exitTxt4')}}</van-button>
    </div>
  </van-dialog>
</template>

<script>
export default {
  props: {
    noAnswer: {
      type: Number,
      default: 0
    },
    toPath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false,
    };
  },
  methods: {
    close () {
      this.show = false
    },
    leave () {
      this.$router.push(this.toPath)
    },
    goOn () {
      this.show = false
    },
  }
};
</script>

<style lang='less' scoped>
.van-dialog {
  width: 652px;
  height: 788px;
  font-family: Source Han Sans SC;
  /deep/.van-dialog__header {
    width: 70%;
    margin: auto;
    border-bottom: 1px solid #ccc;
    margin-bottom: 106px;
    padding-bottom: 20px;
  }
  .van-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 40px;
    top: 32px;
  }
  /deep/.van-dialog__content {
    text-align: center;
    p {
      margin-bottom: 94px;
      margin-top: 90px;
      color: #333;
      font-size: 36px;
    }
    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      .van-button {
        width: 50%;
      }
      .van-button:first-child {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
